<template>
  <!-- eslint-disable vue/camelcase -->
  <div>
    <div class="row">
      <div class="col-12">
        <div class="row mb-md-2">
          <div class="col-sm-12 col-md-6">
            <div class="form-inline">
              <label>
                Show&nbsp;
              </label>
              <b-form-select
                  v-model="datatable.queries.per_page"
                  class="ml-1 mr-1"
                  size="sm"
                  :options="[10, 15, 25, 50, 100]"
                  @change="getWidgets"
              ></b-form-select>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="dataTables_filter text-md-right">
              <span class="widget-buttons">
                <button type="button" class="btn btn-info btn-sm mr-3" @click="createWidgetSABGuardian">Create Widget for SAB <b-spinner v-if="creatingSAB" class="align-middle" small></b-spinner></button>
                <button type="button" class="btn btn-success btn-sm mr-3" @click="createWidgetGuardian">Create Widget <b-spinner v-if="creatingWidget" class="align-middle" small></b-spinner></button>
                <vue-json-to-csv :json-data="widgets" :csv-title="csvTitle()">
                  <button class="btn btn-dark-blue btn-sm"><Feather class="icon-xs align-middle" type="download" /> Export</button>
                </vue-json-to-csv>
              </span>
              <label class="d-inline-flex align-items-center ml-3">
                Search:
                <b-form-input
                    v-model="datatable.queries.search"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
              <button
                  class="ml-1 btn btn-dark-blue btn-sm"
                  @click="getWidgets"
              >
                <i class="uil uil-search-alt"></i>
              </button>
            </div>
          </div>
        </div>
        <div>
          <router-link
              :to="{
                        name: 'agency.widgets.index',
                      }"
              class="btn btn-outline-dark active"
          >Active</router-link>
          <router-link
              :to="{
                        name: 'agency.widgets.archived',
                      }"
              class="btn btn-outline-dark ml-2"
          >Archived</router-link>
        </div>
        <div class="card mt-3">
          <div class="card-body">
            <template v-if="!loadingWidgets">
              <div class="table-responsive mb-0">
                <b-table
                  :items="widgets"
                  :fields="datatable.columns"
                  :sort-by.sync="datatable.queries.sort_by"
                  :sort-desc.sync="datatable.queries.sort_desc"
                  :per-page="datatable.queries.per_page"
                  data-export-types="['csv']"
                  :no-local-sorting="true"
                  @sort-changed="sortChanged"
                >
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <template v-slot:cell(installation)="data">
                    <button type="button" class="btn btn-default btn-sm mr-md-1" @click="addWidgetModal(data.item)">Add Widget</button>
                    <button type="button" class="btn btn-default btn-sm mr-md-1" @click="addWidgetModal(data.item, true)">Add Popup</button>
                    <a :href="showLink(data.item)"
                       target="_blank"
                        class="btn btn-default btn-sm"
                    >Review Page</a>
                    {{data.id}}
                  </template>
                  <template v-slot:cell(actions)="data">
                    <button
                      class="btn btn-default btn-sm ml-1"
                      @click="editWidget(data)"
                    >
                      <b-spinner v-if="gettingWidget" small />
                      Edit Widget
                    </button>
                    <router-link
                        :to="{
                        name: 'agency.reviews',
                        params: { business: data.item.business_id },
                      }"
                        class="btn btn-default btn-sm ml-1"
                    >
                      See Reviews
                    </router-link>
                    <router-link
                        :to="{
                        name: 'agency.businesses.edit',
                        params: { id: data.item.business_id },
                      }"
                        class="btn btn-default btn-sm ml-1"
                    >
                      Edit Business
                    </router-link>
                    <button v-if="isAdmin" type="button" class="btn btn-default btn-sm ml-1" title="Re-scrape" @click="reScrape(data.item.business_id)">
                      <b-spinner v-if="rescraping" class="align-middle" small></b-spinner>
                      <i v-else class="uil uil-copy"></i>
                    </button>
                    <b-button
                      variant="link"
                      title="Delete"
                      size="lg"
                      class="btn-delete"
                      :disabled="loadingRemove === data.item.id"
                      @click="remove(data.item)"
                    >
                      <b-spinner v-if="loadingRemove === data.item.id" small />
                      <i v-else class="uil uil-trash-alt"></i>
                    </b-button>
                  </template>
                </b-table>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ totalWidgets }} widgets
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                        v-model="datatable.queries.page"
                        :total-rows="totalWidgets"
                        :per-page="datatable.queries.per_page"
                        @change="onPageChanged"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="showDeleteModal" title="Delete Widget">
      <p v-if="selectedWidget"
        >Are you sure you want to delete the widget for"{{
          selectedWidget.business.business_name
        }}"?</p
      >
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false"
          >Cancel</b-button
        >
        <b-button variant="danger" @click="handleRemove">Delete</b-button>
      </template>
    </b-modal>
    <b-modal v-model="showAddWidgetCode" :title-html="addCodeModalHeader" :hide-footer="true" size="lg" @hidden="editorHTML = null">
      <h5>Copy and paste this code into desired place of your website</h5>
      <textarea v-model="editorHTML" class="form-control" rows="8" />
      <button class="btn btn-dark-blue btn-sm mt-4" @click="copyWidgetCode(editorHTML)">Copy Embed Code</button>
    </b-modal>
    <SABWidgetGenerator @widgetCreated="sabWidgetCreated" />
    <b-modal v-model="showCreateWidgetModal" size="lg" :hide-footer="true" :hide-header="true" modal-class="widget-modal">
      <div class="text-center">
        <h4>Create Widget</h4>
        <p>
          <review-widget-generator
              v-if="user && user.agency"
              ref="rlg"
              :search-text="widgetDefaultText"
              class="widget-creator"
              :agency-id="user.agency.id"
              :starting-step="1"
              header-text="Search For Your Business"
              background-color="#ffffff"
              main-text-color="#222222"
              primary-button-background-color="#34a853"
              primary-button-text-color="#ffffff"
              :google-api-key="user.agency.landing_page.admin_settings.google_api_key"
              place-holder="Search"
              :manually-created="true"
              button-text="Generate My Widget"
              :force-busy="createIsBusy"
              @prospectCreated="prospectCreated"
          ></review-widget-generator>
        </p>
      </div>
    </b-modal>
    <b-modal v-model="showEditor" :hide-footer="true" modal-class="widget-editor" header-class="align-items-center">
      <template v-slot:modal-header>
        <h5 class="text-white">{{editor.editingWidget.business.business_name}}</h5>
        <div class="float-right">
          <button class="btn btn-default btn-sm mr-2" @click="showEditor = false">
            Cancel
          </button>
          <button class="btn btn-success btn-sm mr-2" @click="saveEditor(false)">
            Save
          </button>
          <button class="btn btn-success btn-sm" @click="saveEditor(true)">
            Save & Exit
          </button>
        </div>
      </template>
      <div class="container-fluid">
        <div v-if="editor.editingWidget" class="row">
          <div id="Navbar" class="sidebar">
            <div class="left-nav-side float-left">
              <button :class="{ 'active': editor.editing === 'layout' }" @click="displayLayout">
                <i class="uil uil-window-grid d-block"></i>
                Layout
              </button>
              <button :class="{ 'active': editor.editing === 'review' }" @click="editor.editing = 'review'">
                <i class="uil uil-comment-alt-heart d-block"></i>
                Review
              </button>
              <button :class="{ 'active': editor.editing === 'style' }" @click="editor.editing = 'style'">
                <i class="uil uil-brush-alt d-block"></i>
                Style
              </button>
              <button :class="{ 'active': editor.editing === 'popup' }" @click="displayPopup">
                <i class="uil uil-laptop d-block"></i>
                Popup
              </button>
              <button :class="{ 'active': editor.editing === 'review_gate' }" @click="displayReivewGate">
                <i class="uil uil-comment-alt-block d-block"></i>
                Page
              </button>
              <button :class="{ 'active': editor.editing === 'settings' }" @click="editor.editing = 'settings'">
                <i class="uil uil-cog d-block"></i>
                Settings
              </button>
            </div>
            <ul class="nav nav-sidebar">
              <li v-if="editor.editing === 'layout'" class="layout">
                <h4>Layout</h4>
                <ul class="list-unstyled">
                  <li>
                    <span class="style-selector">
                      <template v-for="layout in editor.layouts">
                        <input :id="layout.name" :key="layout.name" v-model="editor.editingWidget.layout_style" :value="layout.name" type="radio" class="btn-check"  autocomplete="off" />
                        <label :key="layout.name + 'label'" class="btn" :class="{ 'checked': editor.editingWidget.layout_style === layout.name }" :for="layout.name" >
                          <img :alt="layout.label" :src="'/images/widget-layouts/' + layout.name + '-icon.png'" />
                          <span>{{ layout.label }}</span>
                        </label>
                      </template>
                    </span>
                  </li>
                  <li v-if="editor.editingWidget.layout_style === 'grid'"><span>Number of Rows</span> <v-select v-model="editor.editingWidget.layout_grid.rows" :clearable="false" taggable :options="[1,2,3,4,5,6,7]" /></li>
                  <li v-if="editor.editingWidget.layout_style === 'list'"><span>Number of Rows</span> <v-select v-model="editor.editingWidget.layout_list.total_to_show" :clearable="false" taggable :options="[1,2,3,4,5,6,7]" /></li>
                  <li v-if="editor.editingWidget.layout_style === 'slider'"><span>Number of Columns</span> <v-select v-model="editor.editingWidget.layout_slider.columns" :clearable="false" taggable :options="[1,2,3,4]" /></li>
                  <li v-if="editor.editingWidget.layout_style === 'masonry'"><span>Number To Show</span> <v-select v-model="editor.editingWidget.layout_masonry.total_to_show" :clearable="false" taggable :options="[1,2,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]" /></li>
                  <li v-if="!editor.editingWidget.layout_style !== 'popup'">
                    <span>Include Title</span>
                    <ToggleButton v-model="editor.editingWidget.include_title" :height="20" :width="45" color="#00b050" :sync="true"></ToggleButton>
                  </li>
                  <li v-if="editor.editingWidget.include_title" class="d-block">
                    <span>Title</span>
                    <TextInput v-model="editor.editingWidget.title" class="d-block mt-2" />
                  </li>
                  <li><span>Review Header</span> <ToggleButton v-model="editor.editingWidget.show_review_header" :height="20" :width="45" color="#00b050" :sync="true"></ToggleButton></li>
                  <li><span>Write a Review</span> <ToggleButton v-model="editor.editingWidget.show_write_review" :height="20" :width="45" color="#00b050" :sync="true"></ToggleButton></li>
                  <li><span>Full Width</span> <ToggleButton v-model="editor.editingWidget.full_width" :height="20" :width="45" color="#00b050" :sync="true"></ToggleButton></li>
                  <li v-if="!editor.editingWidget.full_width" class="d-block">
                    <vue-slider v-model="editor.editingWidget.defined_width"  :lazy="true" color="#1494ff" tooltip="none" :height="10" :min="700" :max="1920"></vue-slider>
                    <div class="d-block text-center">Width: {{editor.editingWidget.defined_width}}px</div>
                  </li>
                </ul>
              </li>
              <li v-if="editor.editing === 'review'" class="review">
                <h4>Reviews</h4>
                <ul class="list-unstyled">
                  <li><span>Show Author Name</span> <ToggleButton v-model="editor.editingWidget.review_style.show_author_name" :height="20" :width="45" color="#00b050" :sync="true"></ToggleButton></li>
                  <li><span>Show Author Picture</span> <ToggleButton v-model="editor.editingWidget.review_style.show_author_picture" :height="20" :width="45" color="#00b050" :sync="true"></ToggleButton></li>
                  <li><span>Show Date</span> <ToggleButton v-model="editor.editingWidget.review_style.show_date" :height="20" :width="45" color="#00b050" :sync="true"></ToggleButton></li>
                  <li><span>Show Rating</span> <ToggleButton v-model="editor.editingWidget.review_style.show_rating" :height="20" :width="45" color="#00b050" :sync="true"></ToggleButton></li>
                  <li><span>Show Review Source</span> <ToggleButton v-model="editor.editingWidget.review_style.show_source" :height="20" :width="45" color="#00b050" :sync="true"></ToggleButton></li>
                  <li class="d-inline-block text-center"><span class="mb-2 d-block">How To Display Review Text</span><toggle-switch v-model="editor.editingWidget.review_style.show_read_more_button" :options="{ layout: { color: '#ffffff', backgroundColor: '#5f7cab', selectedBackgroundColor: '#00b050' }, size: { fontSize: .8, height: 1.5, padding: 0.1, width: 13.5 }, config: { items: [{ name: 'Short', value: 'true' }, { name: 'Full', value: 'false' }] } }" /></li>
                </ul>
              </li>
              <li v-if="editor.editing === 'style'" class="layout">
                <h4>Layout</h4>
                <ul class="list-unstyled">
                  <li class="d-inline-block text-center"><span class="mb-2 d-block">Color Scheme</span><toggle-switch v-model="editor.editingWidget.settings.color_scheme" :options="{ layout: { color: '#ffffff', backgroundColor: '#5f7cab', selectedBackgroundColor: '#00b050' }, size: { fontSize: .8, height: 1.5, padding: 0.1, width: 13.5 }, config: { items: [{ name: 'Light', value: 'light' }, { name: 'Dark', value: 'dark' }] } }" @change="updateColorScheme" /></li>
                  <li>
                    <span>Font</span>
                    <v-select v-model="editor.editingWidget.settings.font" :searchable="false" :clearable="false" :options="editor.fonts" label="frontend_label">
                      <template v-slot:option="{ frontend_label, font_family }">
                        <span :style="'font-family:' + font_family">{{frontend_label}}</span>
                      </template>
                    </v-select>
                  </li>
                  <li>
                    <span>Background Color</span>
                    <PopperColor v-model="editor.editingWidget.appearance_review.background_color" title="Background Color" />
                  </li>
                  <li>
                    <span>Text Color</span>
                    <PopperColor v-model="editor.editingWidget.appearance_review.text_color" title="Text Color" />
                  </li>
                  <li>
                    <span>Title Color</span>
                    <PopperColor v-model="editor.editingWidget.appearance_review.title_color" title="Title Color" />
                  </li>
                  <li><span>Text Font Size</span> <v-select v-model="editor.editingWidget.appearance_review.font_size" :clearable="false" taggable :options="[10,12,14,16,18,20,22,24,26,28]" /></li>
                  <li>
                    <span>Write A Review Background</span>
                    <PopperColor v-model="editor.editingWidget.appearance_review.write_review_background_color" title="Write Review Background Color" />
                  </li>
                  <li>
                    <span>Write A Review Text</span>
                    <PopperColor v-model="editor.editingWidget.appearance_review.write_review_text_color" title="Write Review Text Color" />
                  </li>
                  <li>
                    <span>Rating Color</span>
                    <PopperColor v-model="editor.editingWidget.appearance_review.rating_color" title="Rating Color" />
                  </li>
                  <li>
                    <span>Corner Radius</span>
                    <div class="radius-selector">
                      <template v-for="radius in editor.border_radius">
                        <input :id="radius.image" :key="radius.value" v-model="editor.editingWidget.appearance_review.border_radius" :value="radius.value" type="radio" class="btn-check"  autocomplete="off" name="border-radius" />
                        <label :key="radius.image + 'label'" class="btn" :class="{ 'checked': editor.editingWidget.appearance_review.border_radius === radius.value }" :for="radius.image" >
                          <img :alt="radius.value" :src="'/images/review-corners/' + radius.image + '.png'" />
                        </label>
                      </template>
                    </div>
                  </li>
                  <li><span>Padding</span> <v-select v-model="editor.editingWidget.appearance_review.gap" :clearable="false" taggable :options="[10,12,15,20,25,30]" /></li>
                </ul>
              </li>
              <li v-if="editor.editing === 'popup'" class="popup">
                <h4>Popup</h4>
                <ul class="list-unstyled">
                  <li class="d-inline-block text-center"><span class="mb-2 d-block">Position</span><toggle-switch v-model="editor.editingWidget.layout_floating_rotator.position" :options="{ layout: { color: '#ffffff', backgroundColor: '#5f7cab', selectedBackgroundColor: '#00b050' }, size: { fontSize: .8, height: 1.5, padding: 0.1, width: 13.5 }, config: { items: [{ name: 'Left', value: 'left' }, { name: 'Right', value: 'right' }] } }" /></li>
                  <li>
                    <span>Delay</span>
                    <v-select v-model="editor.editingWidget.layout_floating_rotator.delay" class="rotator-delay" taggable :clearable="false" :options="[5,6,7,8,9]">
                    </v-select>
                  </li>
                </ul>
              </li>
              <li v-if="editor.editing === 'review_gate'" class="reviewgate">
                <h4>Review Gate</h4>
                <ul class="list-unstyled">
                  <li>
                    <TextInput v-model="editor.editingWidget.gate_body_title" label="Body Title" class="w-100" />
                  </li>
                  <li>
                    <div class="w-100">
                      <label class="d-block">Body Text</label>
                      <BTextarea v-model="editor.editingWidget.gate_body_text" rows="6" class="w-100" />
                    </div>
                  </li>
                  <li>
                    <TextInput v-model="editor.editingWidget.gate_good_label" label="Good Experience Label" class="w-100" />
                  </li>
                  <li>
                    <TextInput v-model="editor.editingWidget.gate_bad_label" label="Bad Experience Label" class="w-100" />
                  </li>
                </ul>
              </li>
              <li v-if="editor.editing === 'settings'" class="settings">
                <h4>Settings</h4>
                <ul class="list-unstyled">
                  <li>
                    <span>Reviews Only With Text</span>
                    <ToggleButton v-model="editor.editingWidget.settings.show_only_text_reviews" :height="20" :width="45" color="#00b050" :sync="true"></ToggleButton>
                  </li>
                  <li>
                    <span>Minimum Rating</span>
                    <v-select v-model="editor.editingWidget.settings.min_rating" class="min-rating" :clearable="false" :options="[5,4,3,2,1]">
                    </v-select>
                  </li>
                  <li>
                    <span>Schema.org</span>
                    <ToggleButton v-model="editor.editingWidget.settings.enable_schema" :height="20" :width="45" color="#00b050" :sync="true"></ToggleButton>
                  </li>
                  <li>
                    <span>Enable External Links</span>
                    <ToggleButton v-model="editor.editingWidget.settings.enable_external_links" :height="20" :width="45" color="#00b050" :sync="true"></ToggleButton>
                  </li>
                  <li>
                    <span>Open Links In New Window</span>
                    <ToggleButton v-model="editor.editingWidget.settings.open_links_in_new_window" :height="20" :width="45" color="#00b050" :sync="true"></ToggleButton>
                  </li>
                  <li>
                    <span>Gate Reviews</span>
                    <ToggleButton v-model="editor.editingWidget.settings.gate_review" :height="20" :width="45" color="#00b050" :sync="true"></ToggleButton>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="editor-content">
            <review-gate v-if="editor.editing === 'review_gate'" :widget="editor.editingWidget" />
            <iframe v-else id="preview-frame" class="w-full h-full" :srcdoc="iframeSrc" frameborder="0" :style="'background-color:' + editor.background_color + ';'"></iframe>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<style lang="scss" scoped>
.status-filter{
  text-transform: capitalize;
}
</style>
<style lang="scss">
iframe#preview-frame{
  width: 100%;
  height: calc(100% - 5px);
}
:root{
  --vs-search-input-color: #000;
  --vs-selected-color: #fff;
  --vs-colors--dark: #000;
  --vs-search-input-bg: transparent;
  --vs-border-width:0;
  --vs-controls-color:#fff;
}
.v-select{
  &.rotator-delay span.vs__selected:after {
    content: 's';
  }
  &.min-rating span.vs__selected:after {
    content: 'Stars';
    padding-left: 2px;
  }
}
.vs__search[readonly]{
  display: none;
}
.v-select:not(.vs--open){
  .vs__search{
    display: none;
  }

}
span.vs__selected{
  right: 0;
}
.vs--unsearchable {
  &.vs--loading .vs__selected, &.vs--open .vs__selected {
    position: relative !important;
  }
}

div#image_overlay_panel{
  z-index: 100000000 !important;
}
.tooltip-inner{
  max-width: 400px;
}
.btn-check {
  position: absolute;
  clip: rect(0,0,0,0);
  pointer-events: none;
}
.widget-modal{
  .modal-body{
    padding: 20px;
  }
}
.widget-creator{
  margin: 0;
  padding-top: 0;
  padding-bottom: 10px;
  h3{
    font-size: 16px;
  }
  input, button, .btn{
    font-size: 14px;
  }
}
.pac-container{
  z-index: 100000;
}
.widget-editor{
  padding: 0 !important;
  .modal-dialog {
    width: 100%;
    height: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
  }
  .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
    border: 0;
    .modal-header{
      background: #4f688f;
      color: #fff;
      padding: 5px 15px;
      border-radius: 0;
      border: 0;
    }
    .modal-body{
      padding: 0;
    }
  }
  .left-nav-side{
    width: 50px;
    font-size: 10px;
    margin-right: 10px;
    text-align: center;
    button{
      margin-bottom: 15px;
      border: 0;
      appearance: none;
      background: none;
      color: #bfbfbf;
      i{
        font-size: 25px;
        line-height: 25px;
      }
      &.active, &:hover{
        color: #fff;
      }
    }
  }
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  .nav-sidebar{
    width: calc(100% - 60px);
    > li{
      list-style: none;
      width: 100%;
      > h4{
        margin-top: 0;
        color: #fff;
      }
      > ul{
        > li{
          background: #51688f;
          border-radius: 8px;
          padding: 5px 10px;
          margin-bottom: 10px;
          width: 100%;
          clear: both;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          > span{
            flex: 1;
            flex-wrap: nowrap;
          }
          .vue-js-switch{
            margin-bottom: 0;
          }
          /*.vue-js-switch{
            display: inline-flex;
            flex: 1;
            justify-content: flex-end;
            margin-bottom: 0;
          }
          .v-select{
            display: inline-flex;
            flex: 1;
            justify-content: flex-end;
            span.vs__selected{
              position: relative !important;
              + input{
                position: absolute;
                clip: rect(0, 0, 0, 0);
              }
            }
          }*/
        }
      }
    }
    .style-selector{
      label{
        min-height: 120px;
        &:hover{
          color: #fff;
        }
        width: 50%;
        border: 4px solid #51688f;
        &.checked{
          border: 4px solid #00b050;
        }
        img{
          max-width: 100%;
          height: auto;

        }
        span{

        }
      }
    }
    .radius-selector{
      label.btn{
        padding: 3px;
        margin-left: 7px;
        margin-bottom: 0;
        background: #5f7cab;
        &.checked, &:hover{
          background: #00b050;
        }
        img{
          max-height: 18px;
        }
      }
    }
    .v-select ul[role="listbox"]{
      z-index: 1000;
      color: #000;
    }
/*    .v-select:not(.vs--open){
      .vs__search{
        display: none;
      }
    }*/

  }
  .sidebar{
    background-color: #284373;
    color: #fff;
    padding-right: 20px;
    padding-top: 20px;
    min-height: calc(100vh - 52px);
    max-width: 360px;
    width: 100%;
  }
  .editor-content{
    flex: 1;
  }
  --track-width: 45px !important;
  --track-height: 25px !important;
  --track-active-color: #2196F3;
}
</style>
<script>
import VueJsonToCsv from 'vue-json-to-csv';
import EmbedForm from '../../../../embed-form/src/components/Form';
import { ToggleButton } from 'vue-js-toggle-button';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import ToggleSwitch from 'vuejs-toggle-switch/src/components/ToggleSwitch';
import PopperColor from "@components/Common/PopperColor";
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import Vue from "vue";
import ReviewGate from '@components/Business/ReviewGate';
import SABWidgetGenerator from "@components/Common/SABWidgetGenerator";
export default {
  components:{
    VueJsonToCsv,
    ToggleButton,
    'review-widget-generator': EmbedForm,
    'v-select': vSelect,
    'toggle-switch': ToggleSwitch,
    PopperColor,
    VueSlider,
    'review-gate': ReviewGate,
    SABWidgetGenerator
  },
  data() {
    return {
      json_export_labels:{

      },
      datatable: {
        items: [],
        totalItems: 1,
        columns: [
          { key: 'small_date', label: 'Date', sortable: true },
          { key: 'business.business_name', label: 'Business', sortable: true },
          { key: 'installation', label: 'Installation', class:'installation' },
          { key: 'actions', label: 'Action', class:'actions', thStyle: { width: "40%" }  },
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'active',
          prospect_status:'all',
          search: null,
          sort_by: 'id',
          sort_desc: true,
        },
      },
      creatingWidget:false,
      creatingSAB:false,
      addCodeModalHeader:'',
      widgetDefaultText:'',
      showDeleteModal: false,
      selectedWidget: null,
      loadingWidgets: false,
      loadingImpersonate: false,
      loadingRemove: null,
      showCreateWidgetModal: false,
      showCreateSABWidgetModal:false,
      createIsBusy:false,
      showEditor:false,
      showAddWidgetCode:false,
      editorHTML:null,
      editor2HTML:null,
      gettingWidget:false,
      rescraping:false,
      gateLink:'',
      editor:{
        background_color:'#ffffff',
        has_loaded:false,
        editing:'layout',
        editingWidget: {
          layout_style:null,
          widget_style:null,
          gate_body_title:'',
          gate_body_text:'',
          gate_good_label:'',
          gate_bad_label:''
        },
        layouts:[
          { label: 'Grid', name: 'grid' },
          { label: 'Masonry', name: 'masonry' },
          { label: 'List', name: 'list' },
          { label: 'Carousel', name: 'slider' }
        ],
        border_radius:[
          { image: 'square', value: 0 },
          { image: 'rounded', value: 8 },
          { image: 'rounded-heavy', value: 20 },
        ],
        fonts:[
          {
            backend_name:"system",
            frontend_label:"Apply From Website",
            href:null,
            font_family:null
          },
          {
            backend_name:"Montserrat",
            frontend_label:"Montserrat",
            href:"https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto:wght@300;400&display=swap",
            font_family:"'Montserrat', sans-serif;"
          },
          {
            backend_name:"Nunito",
            frontend_label:"Nunito",
            href:"https://fonts.googleapis.com/css2?family=Nunito&display=swap",
            font_family:"'Nunito', sans-serif;"
          },
          {
            backend_name:"Oswald",
            frontend_label:"Oswald",
            href:"https://fonts.googleapis.com/css2?family=Oswald&display=swap",
            font_family:"'Oswald', sans-serif"
          },
          {
            backend_name:"Roboto",
            frontend_label:"Roboto",
            href:"https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap",
            font_family:"'Roboto', sans-serif"
          },
        ]
      }
    }
  },

  computed: {
    isAdmin(){
      return localStorage.getItem('main_access_role') === 'Admin';
    },
    widgets() {
      return this.$store.getters['widgets/all'] || []
    },

    totalWidgets() {
      return this.$store.getters['widgets/total']
    },

    reload() {
      return this.$store.getters['widgets/reload']
    },

    user() {
      return this.$store.getters['auth/user']
    },
    iframeSrc(){
      const appDomain = process.env.NODE_ENV === 'development' ? 'https://review-widget-app.dev/widget/dist/index.0641b553.js' : 'https://u.reviewour.biz/widget/dist/index.js';
      return '<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto&display=swap" />'+
          "\n"+
          '<scr'+'ipt type="module" src="' + appDomain + '"></scr'+'ipt>'+
          `<div style="font-family: 'Roboto', sans-serif;" class="preview"><div class="preview-widget"><div class="rwg-app-${this.editor.editingWidget.uuid}" id="rwg-preview"></div></div></div>`;
    },
    settingsString(){
      return JSON.stringify(this.editor.editingWidget);
    },
    layoutStyle(){
      return this.editor.editingWidget.layout_style;
    }
  },
  watch: {
    reload: (val) => {
      console.log(val)
    },
    layoutStyle(v,o){
      this.editor.editingWidget.widget_style = v;
    },
    settingsString(value,oldValue){
      const elem = document.getElementById('preview-frame');
      if(elem !== null && this.editor.has_loaded){
        let jsonSettings = JSON.parse(value);
        // Unset Reviews so we are not posting them to the server.
        jsonSettings.reviews = [];
        jsonSettings.review_style.show_read_more_button = (jsonSettings.review_style.show_read_more_button === 'true');
        elem.contentWindow.document.getElementById('rwg-preview').dispatchEvent(new CustomEvent('settings-changed', { detail:{settings:jsonSettings, items:[], widget_style: this.editor.editingWidget.widget_style} }));
      }
    }
  },

  created() {
    this.$store.dispatch('widgets/setCurrentPage', 'index')
  },

  mounted() {
    this.getWidgets();

    if (this.user.agency.is_new) {
      this.showWelcomeModal = true

      this.$store.dispatch('agency/toggleIsNew', this.user.agency)
    }
    if(window.location.search.includes('create_widget')){
      this.$store
      .dispatch('widgets/canCreate')
      .then((response) => {
        if(response.success){
          this.showCreateWidgetModal = true;
        }else{
          response.messages.forEach(message => {
            Vue.$toast.open({
              message: message,
              type: 'error',
              duration: 15000,
            })
          });
        }
        let query = Object.assign({}, this.$route.query);
        delete query.create_widget;
        if(query.hasOwnProperty('business_name')) {
          this.widgetDefaultText = decodeURI(query.business_name);
          delete query.business_name;
        }
        this.$router.replace({ query });
      })
      .catch((err) => {
        console.log(err);
      })
    }
    // Load Fonts
    this.editor.fonts.forEach(font => {
      if(font.href !== null) {
        const thisFont = document.createElement('link');
        thisFont.setAttribute('rel', 'stylesheet');
        thisFont.setAttribute('href', font.href);
        document.head.appendChild(thisFont);
      }
    });
    this.editor.has_loaded = true;
  },

  methods: {
    createWidgetGuardian(){
      this.creatingWidget = true;
      this.$store
          .dispatch('widgets/canCreate')
          .then((response) => {
            this.creatingWidget = false;
            if(response.success){
              this.showCreateWidgetModal = true;
            }else{
              response.messages.forEach(message => {
                Vue.$toast.open({
                  message: message,
                  type: 'error',
                  duration: 15000,
                })
              });
            }
          })
          .catch((err) => {
            this.creatingWidget = false;
            console.log(err);
          })
    },
    createWidgetSABGuardian(){
      this.creatingSAB = true;
      this.$store
          .dispatch('widgets/canCreate')
          .then((response) => {
            this.creatingSAB = false;
            if(response.success){
              this.$bvModal.show('SABModal');
            }else{
              response.messages.forEach(message => {
                Vue.$toast.open({
                  message: message,
                  type: 'error',
                  duration: 15000,
                })
              });
            }
          })
          .catch((err) => {
            this.creatingSAB = false;
            console.log(err);
          })
    },
    showLink(widget) {
      const appDomain = process.env.NODE_ENV === 'development' ? 'https://review-widget-app.dev/review/' : 'https://u.reviewour.biz/review/';
      const fakeId = widget.id + 12000;
      return `${appDomain}${fakeId}`;
    },

    copyLink() {
      this.$refs.linkText.select()
      this.$refs.linkText.setSelectionRange(0, 999999)
      document.execCommand('copy')

      Vue.$toast.open({
        message: 'Copied',
        type: 'success',
        duration: 5000,
      })
    },
    saveEditor(shouldClose = false){
      this.$store
          .dispatch('widgets/update', this.editor.editingWidget)
          .then(() => {
            if(shouldClose){
              this.showEditor = false;
            }
          })
          .catch((err) => {
            console.log(err);
            if(shouldClose){
              this.showEditor = false;
            }
          })
    },
    reScrape(businessId){
      this.rescraping = true;
      this.$store
          .dispatch('widgets/reScrape', businessId)
          .then((resp) => {
            this.rescraping = false;
            if(!resp.success) {
              Vue.$toast.open({
                message: resp.message,
                type: 'error',
                duration: 5000,
              })
            }
          })
          .catch((err) => {
            console.log(err);
            this.rescraping = false;
          })
    },
    addWidgetModal(widget, isFloating = false){
      let widgetDiv = `<div class="rwg-app-${widget.uuid}"></div>`;
      this.addCodeModalHeader = "Add Widget Code";
      if(isFloating){
        this.addCodeModalHeader = "Add Popup Code";
        widgetDiv = `<div class="rwg-app-${widget.uuid} floating"></div>`;
      }
      const scriptUrl = process.env.NODE_ENV === 'development' ? 'https://review-widget-app.dev/widget/dist/index.0641b553.js' : 'https://u.reviewour.biz/widget/dist/index.js';
      this.editor2HTML = '<!-- Start Review Widget Generator -->'+
          "\n"+
          widgetDiv +
          "\n"+
          '<!-- End Review Widget Generator  -->';
      this.editorHTML = '<!-- Start Review Widget Generator -->'+
          "\n"+
          widgetDiv +
          "\n"+
          '<scr'+'ipt src="'+scriptUrl+'"></s'+'cript>'+
          "\n"+
          '<!-- End Review Widget Generator  -->';
      this.showAddWidgetCode = true;
    },
    copyWidgetCode(code) {
      navigator.clipboard.writeText(code.replaceAll('&lt;','<'));
      Vue.$toast.open({
        message: 'Code Copied',
        type: 'success',
        duration: 5000,
      })
    },
    updateColorScheme(){
      if(this.editor.editingWidget.settings.color_scheme === 'dark'){
        this.editor.editingWidget.appearance_review.text_color = '#fff';
        this.editor.editingWidget.appearance_review.title_color = '#fff';
        this.editor.editingWidget.appearance_review.background_color = '#2a2a2a';
        this.editor.background_color = '#171717';
      }else{
        this.editor.editingWidget.appearance_review.text_color = '#000000';
        this.editor.editingWidget.appearance_review.title_color = '#000000';
        this.editor.editingWidget.appearance_review.background_color = '#f2f2f2';
        this.editor.background_color = '#ffffff';
      }
      this.editor.editingWidget.appearance_review.write_review_text_color = '#ffffff';
      this.editor.editingWidget.appearance_review.write_review_background_color = '#4085f2';
    },
    displayLayout(){
      this.editor.editingWidget.widget_style = this.editor.editingWidget.layout_style;
      this.editor.editing = 'layout';
    },
    displayPopup(){
      this.editor.editingWidget.widget_style = 'floating_rotator';
      this.editor.editing = 'popup';
    },
    displayReivewGate(){
      this.editor.editing = 'review_gate';
    },
    closeToolTips(){
      this.$root.$emit('bv::hide::tooltip');
    },
    editWidget(widget){
      this.getWidget(widget);
    },
    prospectCreated(business){
      // Create Widget
      this.createIsBusy = true;
      this.$store
          .dispatch('widgets/create', { business_id: business.id })
          .then(() => {
            this.showCreateWidgetModal = false;
            this.createIsBusy = false;
            this.getWidgets();
          })
          .catch((err) => {
            console.log(err);
            this.showCreateWidgetModal = false;
            this.createIsBusy = false;
          })
    },
    csvTitle(){
      return 'review-widget-generator-'+Date.now()
    },
    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.page = 1
      this.getWidgets()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getWidgets()
    },
    sabWidgetCreated(){
      this.getWidgets();
      this.$bvModal.hide('SABModal');
    },
    getWidgets() {
      this.loadingWidgets = true

      this.$store
        .dispatch('widgets/getAll', {
          queries: this.datatable.queries,
          page: 'index',
        })
        .then((res) => {
          this.loadingWidgets = false
        })
        .catch(() => {
          this.loadingWidgets = false
        })
    },
    getWidget(widget) {
      this.gettingWidget = true;
      this.$store
          .dispatch('widgets/find', widget.item.id)
          .then((res) => {
            const widget = res;
            // change to string because toggle button does't work with booleans
            widget.review_style.show_read_more_button = widget.review_style.show_read_more_button.toString();
            if(!widget.appearance_review.hasOwnProperty('title_color')){
              // Setting this manually, because this was added after the fact and users would not have this color in the database
              widget.appearance_review.title_color = widget.appearance_review.text_color;
            }
            this.editor.editingWidget = widget;
            this.editor.editingWidget.widget_style = widget.layout_style;
            this.showEditor = true;
          })
          .catch(() => {

          }).finally(() => {this.gettingWidget = false})
    },

    impersonate(userId) {
      this.loadingImpersonate = true

      this.$store
        .dispatch('auth/impersonate', userId)
        .then((res) => {
          this.$store
            .dispatch('auth/getProfile')
            .then((user) => {
              this.$router.push('/' + user.role.toLocaleLowerCase())
              this.loadingImpersonate = false
            })
            .catch(() => {
              this.loadingImpersonate = false
            })
        })
        .catch(() => {
          this.loadingImpersonate = false
        })
    },

    remove(widget) {
      this.showDeleteModal = true
      this.selectedWidget = widget
    },

    handleRemove() {
      this.showDeleteModal = false
      this.loadingRemove = this.selectedWidget.id
      this.$store
        .dispatch('widgets/archive', this.selectedWidget.id)
        .then(() => {
          this.loadingRemove = null
          this.getWidgets()
        })
        .catch(() => {
          this.loadingRemove = null
        })
    },
  },
}
</script>
<style scoped>
@media screen and (max-width: 1565px){
  .widget-buttons{
    clear: both;
    margin-bottom: 10px;
    display: block;
  }
}
.btn-outline-warning{}
</style>
